<template>
  <a-modal
    title="新建"
    :width="880"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @ok="() => { $emit('ok', selectGoods) }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24" v-if="false">
            <a-form-item label="仓库">
              <a-select
                :not-found-content="warehouse_fetching ? undefined : null"
                :default-active-first-option="false"
                :filter-option="false"
                placeholder="选择仓库"
                v-decorator="['warehouse_id', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                allow-clear
                show-search
                @search="this.warehouseSearch"
                @change="handleWarehouseChange"
              >
                <a-spin v-if="warehouse_fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in dataSource_wms_warehouse" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <!-- @search="handleCargoSpaceSearch"-->
          <a-col :span="24">
            <a-form-item label="库位">
              <a-select
                option-filter-prop="children"
                :filter-option="filterOption"
                placeholder="选择库位"
                allow-clear
                show-search
                v-decorator="['cargo_space_id', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                @change="handleCargoSpaceChange"
              >
                <a-spin v-if="cargo_space_fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in dataSource_wms_cargo_space" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="货品">
              <a-select
                option-filter-prop="children"
                :filter-option="filterOption"
                placeholder="选择货品"
                allow-clear
                show-search
                @change="handleGoodsChange"
                v-decorator="['goods_purchase_id', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
              >
                <a-select-option v-for="d in goodsList" :key="d.goods_purchase_id" :value="d.goods_purchase_id">
                  {{ d.goods_name }} [ 批次：{{ d.goods_purchase_batch }}，库存量：{{ d.available_count }} ]
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { warehouse_list } from '@/api/c_wms_warehouse'
import { cargo_space_list } from '@/api/c_wms_cargo_space'
import moment from 'moment/moment'
import { wms_warehouse_inventory_list } from '@/api/c_wms_period_inventory'
import debounce from 'lodash/debounce'

// 表单字段
const fields = ['id', 'warehouse_id', 'cargo_space_id', 'goods_purchase_id']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      formLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 6 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 14 }
        }
      },
      warehouse_fetching: false,
      cargo_space_fetching: false,
      fetching: false,
      goods_fetching: false,
      corporationList: [],
      selected_warehouse: undefined,
      selected_cargo_space: undefined,
      warehouse_id: undefined,
      cargo_space_id: undefined,
      corporation_id: undefined,
      inventory_id: undefined,
      form: this.$form.createForm(this),
      dataSource_wms_warehouse: [],
      dataSource_wms_cargo_space: [],
      goodsList: [],
      selectGoods: undefined
    }
  },
  created () {
    console.log('custom modal created---', this.model)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
    this.handleGoodsSearch(undefined)
  },
  methods: {
    handleGoodsChange (value) {
      this.selectGoods = undefined
      if (value === undefined) {
        this.handleGoodsSearch(undefined)
      } else {
        this.selectGoods = this.goodsList.find(item => value === item.goods_purchase_id)
        console.log(this.selectGoods)
      }
    },
    handleGenerateBatch () {
      const today = moment()
      const year = today.year()
      const month = today.month() + 1 // 注意月份从0开始，所以要加1
      const date = today.date() > 9 ? today.date() : '0' + today.date()
      if (this.warehouse_id !== 0 && this.warehouse_id !== undefined && this.corporation_id !== 0 && this.corporation_id !== undefined) {
        this.form.setFieldsValue({
          batch: '' + this.warehouse_id + this.corporation_id + year + month + date
        })
      } else {
        this.form.setFieldsValue({
          batch: undefined
        })
      }
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleWarehouseSearch (value) {
      this.dataSource_wms_warehouse = []
        this.warehouse_fetching = true
      warehouse_list({ name: value, id: this.model.warehouse_id }).then((res) => {
        const result = res.data.entries || []
        this.dataSource_wms_warehouse = result
      }).finally(() => {
        this.warehouse_fetching = false
      })
    },
    handleWarehouseChange (value) {
      const objneo = this.dataSource_wms_warehouse.find((item) => {
        return item.id === value
      })
      console.log(objneo, 'objneo', this.dataSource_wms_warehouse)
      const obj = Object.assign({}, objneo)
      console.log(obj)
      this.selected_warehouse = obj
      this.warehouse_id = value
      if (value === undefined) {
        this.handleWarehouseSearch(undefined)
      } else {
        this.handleCargoSpaceSearch(undefined)
      }
    },
    handleCargoSpaceSearch (value) {
      this.dataSource_wms_cargo_space = []
      this.cargo_space_fetching = true
      cargo_space_list({ warehouse_id: this.model.warehouse_id, name: value }).then((res) => {
        const result = res.data.entries || []
        if (result) {
          result.forEach(item => {
            if (item.corporation_id !== -1) {
              const obj_corporation = this.dataSource_wms_cargo_space.find((cargo_space) => {
                return cargo_space.corporation_id === item.corporation_id
              })
              if (obj_corporation === undefined) {
                this.dataSource_wms_cargo_space.push(item)
              }
            }
          })
        }
      }).finally(() => {
        this.cargo_space_fetching = false
      })
    },
    handleCargoSpaceChange (value) {
      const objneo = this.dataSource_wms_cargo_space.find((item) => {
        return item.id === value
      })
      console.log(objneo, 'objneo', this.dataSource_wms_cargo_space)
      const obj = Object.assign({}, objneo)
      console.log(obj)
      this.selected_cargo_space = obj
      this.cargo_space_id = value
      this.goodsList = []
      if (value === undefined) {
        this.handleCargoSpaceSearch(undefined)
      } else {
        this.handleGoodsSearch(undefined)
      }
    },
    handleGoodsSearch (value) {
      this.goodsList = []
      this.goods_fetching = true
      console.log('默认请求数据', this.model)
      const requestData = { warehouse_id: this.model.warehouse_id, cargo_space_id: this.cargo_space_id, barcode: this.model.barcode }
      if (this.selected_warehouse) {
        requestData.goods_type = this.selected_warehouse.goods_type
      }
      console.log('商品搜索', requestData)
      wms_warehouse_inventory_list(requestData).then(({ data }) => {
        const result = data.entries || []
        this.goodsList = result
        this.dataSource_wms_cargo_space = result.map(item => {
          return {
            id: item.cargo_space_id,
            name: item.cargo_space
          }
        })
        console.log('====>>>>>', this.dataSource_wms_cargo_space)
      }).finally(() => {
        this.goods_fetching = false
      })
    },
    warehouseSearch: debounce(function (value) {
      this.handleWarehouseSearch(value)
    }, 800),
    corporationSearch: debounce(function (value) {
      this.handleCargoSpaceSearch(value)
    }, 800),
    goodsSearch: debounce(function (value) {
      this.handleGoodsSearch(value)
    }, 800)
  }
}
</script>
