<template>
  <a-card>
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="仓库">
              <a-select
                :not-found-content="warehouse_fetching ? undefined : null"
                :default-active-first-option="false"
                :filter-option="false"
                show-search
                allow-clear
                v-decorator="[
                  'warehouse_id',
                  {
                    rules: [
                      { required: true, message: '必填项，请填写信息' },
                    ],
                  },
                ]"
                placeholder="请输入仓库名称"
                @search="this.warehouseSearch"
                @change="handleWmsWarehouseChange"
              >
                <a-spin v-if="warehouse_fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in dataSource_wms_warehouse" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="供应商" >
              <a-select
                showSearch
                allow-clear
                v-decorator="['corporation_id', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                placeholder="请输入供应商名称"
                style="width: 100%"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="this.corporationSearch"
                @change="handleCorporationChange"
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in corporationList" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="库位">
              <a-select
                :default-active-first-option="false"
                :filter-option="false"
                allow-clear
                show-search
                v-decorator="[
                  'cargo_space_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="库位名称"
                @change="handleCargoSpaceChange"
              >
                <a-select-option v-for="d in dataSource_wms_cargo_space" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="批次">
              <a-input placeholder="批次" allow-clear v-decorator="['batch', {initialValue: '', rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="套盒货品">
              <a-select
                showSearch
                v-decorator="['barcode', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                placeholder="请输入货品名称"
                style="width: 100%"
                :filter-option="false"
                :not-found-content="goods_fetching ? undefined : null"
                allow-clear
                @search="this.goodsSearch"
                @change="handleGoodsChange"
              >
                <a-spin v-if="goods_fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in goodsList" :key="d.barcode" :value="d.barcode">
                  {{ d.name }}（{{ d.barcode }}）
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="销售模式" >
              <a-select
                showSearch
                allow-clear
                v-decorator="['sell_type', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                placeholder="请选择销售模式"
                style="width: 100%"
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.goods_sell_type">
                  {{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="采购数量">
              <a-input-number @change="handleCountChange" style="width: 150px" :min="1" v-decorator="['count', {initialValue: 1, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="采购价格">
              <a-input-number style="width: 150px" :precision="2" :min="0" v-decorator="['price', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="购买税率">
              <a-input-number style="width: 150px" :precision="4" :min="0" v-decorator="['buy_tax_rate', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="销售税率">
              <a-input-number style="width: 150px" :precision="4" :min="0" v-decorator="['sell_tax_rate', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="other_data.open_self_life">
            <a-form-item label="截止日期方式">
              <a-radio-group
                v-decorator="['expiration_date_method', {initialValue: 1, rules: [{required: other_data.open_self_life, message: '必填项，请填写信息'}]}]"
                @change="expirationDateMethodChange"
              >
                <a-radio :value="1">
                  截止日期
                </a-radio>
                <a-radio :value="2">
                  到期剩余
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="other_data.open_self_life && other_data.expiration_date_method === 1">
            <a-form-item label="结束日期">
              <a-date-picker
                v-decorator="['expiration_date', {rules: [{required: other_data.expiration_date_method === 1, message: '必填项，请填写信息'}]}]"
                show-time
                valueFormat="YYYY-MM-DD"
                format="YYYY-MM-DD"
                placeholder="结束日期"
                :open="endOpen"
                @openChange="handleEndOpenChange"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="other_data.open_self_life && other_data.expiration_date_method === 2">
            <a-form-item label="开始日期">
              <a-date-picker
                v-decorator="['start_date', {rules: [{required: other_data.expiration_date_method === 2, message: '必填项，请填写信息'}]}]"
                show-time
                valueFormat="YYYY-MM-DD"
                format="YYYY-MM-DD"
                placeholder="开始日期"
                @openChange="handleStartOpenChange"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="other_data.expiration_date_method === 2">
            <a-form-item label="到期剩余">
              <a-input type="number" v-model="other_data.time_span">
                <a-select
                  slot="addonAfter"
                  v-model="other_data.time_span_type"
                  style="width: 60px">
                  <a-select-option value="1">
                    日
                  </a-select-option>
                  <a-select-option value="2">
                    月
                  </a-select-option>
                  <a-select-option value="3">
                    年
                  </a-select-option>
                </a-select>
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="13" v-if="false">
            <div style="text-align: left;padding-left: 60px;">
              <a-button @click="addCustomGoodsInUnion">添加套盒内货品</a-button>
            </div>
          </a-col>
          <a-col :span="24" style="text-align: center; padding-left: 60px; padding-right: 60px;" v-if="selectedGoodsListInUnion.length > 0">
            <a-card :bordered="false" hoverable title="套盒内货品">
              <a-table
                size="small"
                rowKey="id"
                :columns="columns"
                :data-source="selectedGoodsListInUnion"
              >
                <span slot="action" slot-scope="text, record">
                  <template>
                    <a @click="chooseInventory(record)">选择</a>
                    <a-divider type="vertical"/>
                    <a-popconfirm
                      title="确定要删除么？"
                      ok-text="确定"
                      cancel-text="取消"
                      @confirm="handleDelete(record)"
                    >
                      <a>删除</a>
                    </a-popconfirm>
                  </template>
                </span>
              </a-table>
            </a-card>
          </a-col>
        </a-row>
        <a-row>
          <a-col span="24">
            <a-divider/>
          </a-col>
          <a-col span="8"></a-col>
          <a-col span="6">
            <a-button @click="handleGoBack">返回</a-button>
          </a-col>
          <a-col span="10">
            <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
    <add-custom-goods-in-union
      ref="createCustomPurchaseModal"
      v-if="custom_purchase_create_visible"
      :visible.sync="custom_purchase_create_visible"
      :loading="purchaseDeclarationConfirmLoading"
      :model="goodsPurchaseMdl"
      @cancel="handleCreateCustomPurchaseCancel"
      @ok="handleCreateCustomPurchaseOk" />
  </a-card>
</template>

<script>
import pick from 'lodash.pick'
import { warehouse_list } from '@/api/c_wms_warehouse'
import { cargo_space_list } from '@/api/c_wms_cargo_space'
import { goods_list, union_goods_list } from '@/api/c_wms_goods'
import { corporation_list } from '@/api/corporation'
import debounce from 'lodash/debounce'
import moment from 'moment/moment'

import AddCustomGoodsInUnion from './AddCustomGoodsInUnion'

import { custom_goods_purchase_create } from '@/api/c_wms_goods_purchase'
import { wms_warehouse_inventory_list } from '@/api/c_wms_period_inventory'
import { multiply } from 'xe-utils'

// 表单字段
const fields = ['id', 'name', 'code', 'wms_commodity_purchase_id']
export default {
  components: {
    AddCustomGoodsInUnion
  },
  data () {
    this.warehouseSearch = debounce(this.handleWarehouseSearch, 800)
    this.corporationSearch = debounce(this.handleCorporationSearch, 800)
    this.goodsSearch = debounce(this.handleGoodsSearch, 800)
    return {
      formLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 6 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 14 }
        }
      },
      warehouse_fetching: false,
      loading: false,
      fetching: false,
      goods_fetching: false,
      endOpen: false,
      custom_purchase_create_visible: false,
      purchaseDeclarationConfirmLoading: false,
      other_data: {
        expiration_date_method: 1,
        time_span: 1,
        time_span_type: '1',
        open_self_life: false,
        selectGoods: this.selectGoods
      },
      corporationList: [],
      selected_warehouse: undefined,
      warehouse_id: undefined,
      corporation_id: undefined,
      form: this.$form.createForm(this),
      dataSource_wms_warehouse: [],
      dataSource_wms_cargo_space: [],
      goodsList: [],
      goodsUnitCount: 1,
      goodsInUnionList: [],
      selectedGoodsListInUnion: [],
      selectGoods: undefined,
      goodsPurchaseMdl: {
        warehouse_id: undefined,
        barcode: undefined
      },
      columns: [
        {
          title: '货品名称',
          align: 'center',
          dataIndex: 'son_goods'
        },
        {
          title: '货品条形码',
          align: 'center',
          dataIndex: 'son_goods_barcode'
        },
        {
          title: '数量',
          align: 'center',
          dataIndex: 'take_count'
        },
        {
          title: '库位',
          dataIndex: 'cargo_space'
        },
        {
          title: '批次',
          align: 'center',
          dataIndex: 'goods_purchase_batch'
        },
        {
          title: '操作',
          align: 'center',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
    this.handleWarehouseSearch(undefined)
    this.handleCorporationSearch(undefined)
  },
  methods: {
    handleCorporationSearch (value) {
      this.corporationList = []
      this.fetching = true
      corporation_list({ name: value }).then(({ data }) => {
        const result = data.entries || []
        this.corporationList = result
      }).finally(() => {
        this.fetching = false
      })
    },
    handleCorporationChange (value) {
      this.corporation_id = value
      if (value === undefined) {
        this.handleCorporationSearch(undefined)
      }
      this.form.setFieldsValue({
        cargo_space_id: undefined
      })
      this.dataSource_wms_cargo_space = []
      this.handleWmsCargoSpaceSearch()
      this.handleGenerateBatch()
    },
    handleGoodsChange (value) {
      this.selectGoods = undefined
      if (value === undefined) {
        this.selectedGoodsListInUnion = []
        this.handleGoodsSearch(undefined)
      } else {
        this.selectGoods = this.goodsList.find(item => value === item.barcode)
        if (this.selectGoods.open_self_life) {
          this.other_data.time_span = this.selectGoods.shelf_life
          this.other_data.open_self_life = this.selectGoods.open_self_life
          this.other_data.time_span_type = '' + this.selectGoods.shelf_life_unit
        } else {
          this.other_data.time_span = 1
          this.other_data.open_self_life = this.selectGoods.open_self_life
          this.other_data.time_span_type = '1'
        }
        this.selectGoods.warehouse_id = this.warehouse_id
        this.other_data.selectGoods = this.selectGoods
        this.handleGoodsInUnion()
      }
    },
    handleCountChange (value) {
      console.log(value)
      this.goodsUnitCount = value
      if (this.selectedGoodsListInUnion) {
        this.selectedGoodsListInUnion = this.selectedGoodsListInUnion.map(item => {
          item.take_count = item.son_goods_count * this.goodsUnitCount
          return item
        })
      }
    },
    handleGenerateBatch () {
      const today = moment()
      const year = today.year()
      const month = today.month() + 1 // 注意月份从0开始，所以要加1
      const date = today.date() > 9 ? today.date() : '0' + today.date()
      if (this.warehouse_id !== 0 && this.warehouse_id !== undefined && this.corporation_id !== 0 && this.corporation_id !== undefined) {
        this.form.setFieldsValue({
          batch: '' + this.warehouse_id + this.corporation_id + year + month + date
        })
      } else {
        this.form.setFieldsValue({
          batch: undefined
        })
      }
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleWarehouseSearch (value) {
      this.dataSource_wms_warehouse = []
        this.warehouse_fetching = true
      warehouse_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.dataSource_wms_warehouse = result
      }).finally(() => {
        this.warehouse_fetching = false
      })
    },
    handleWmsWarehouseChange (value) {
      const objneo = this.dataSource_wms_warehouse.find((item) => {
        return item.id === value
      })
      console.log(objneo, 'objneo', this.dataSource_wms_warehouse)
      const obj = Object.assign({}, objneo)
      console.log(obj)
      this.selected_warehouse = obj
      this.warehouse_id = value
      this.dataSource_wms_cargo_space = []
      this.goodsPurchaseMdl.warehouse_id = this.warehouse_id
      this.form.setFieldsValue({
        cargo_space_id: undefined
      })
      this.form.setFieldsValue({
        barcode: undefined
      })
      this.goodsList = []
      this.selectedGoodsListInUnion = []
      if (value === undefined) {
        this.handleWarehouseSearch(undefined)
      } else {
        this.handleWmsCargoSpaceSearch()
        this.handleGoodsSearch(undefined)
      }
      this.handleGenerateBatch()
    },
    handleWmsCargoSpaceSearch (value) {
      cargo_space_list({ warehouse_id: this.warehouse_id, name: value, corporation_id: this.corporation_id }).then((res) => {
        const result = res.data.entries || []
        if (result) {
          this.dataSource_wms_cargo_space = result
          // result.forEach(item => {
          //   if (item.corporation_id !== -1) {
          //     const obj_corporation = this.dataSource_wms_cargo_space.find((cargo_space) => {
          //       return cargo_space.corporation_id === item.corporation_id
          //     })
          //     if (obj_corporation === undefined) {
          //       this.dataSource_wms_cargo_space.push(item)
          //     }
          //   }
          // })
        }
      })
    },
    handleCargoSpaceChange (value) {
      this.selectedGoodsListInUnion = []
      this.form.setFieldsValue({
        barcode: undefined
      })
    },
    disabledStartDate (startValue) {
      const endValue = this.form.getFieldValue('expiration_date')
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate (endValue) {
      const startValue = this.form.getFieldValue('start_date')
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() >= endValue.valueOf()
    },
    handleStartOpenChange (open) {
      if (!open) {
        this.endOpen = true
      }
    },
    expirationDateMethodChange (e) {
      this.other_data.expiration_date_method = e.target.value
    },
    handleEndOpenChange (open) {
      this.endOpen = open
    },
    handleGoodsSearch (value) {
      this.goodsList = []
      this.goods_fetching = true
      const requestData = { name: value, goods_type: undefined, is_union: true }
      if (this.selected_warehouse) {
        requestData.goods_type = this.selected_warehouse.goods_type
      }
      console.log('商品搜索', requestData)
      goods_list(requestData).then(({ data }) => {
        const result = data.entries || []
        this.goodsList = result
      }).finally(() => {
        this.goods_fetching = false
      })
    },
    handleGoBack () {
      this.$router.go(-1)
    },
    handleSubmit () {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          const filterGoodsList = this.selectedGoodsListInUnion.filter(item => {
            return item.goods_purchase_id === undefined || item.goods_purchase_id === ''
          })
          if (filterGoodsList.length > 0) {
            this.$message.warning('套盒内货品没有选择批次')
            return
          }
          // let dataOK = true
          // this.selectedGoodsListInUnion.forEach(item => {
          //   let goodsInUnion
          //   this.goodsInUnionList.forEach(goods => {
          //     if (goods.son_goods_barcode === item.goods_purchase_barcode) {
          //       goodsInUnion = goods
          //     }
          //   })
          //   if (goodsInUnion) {
          //     const son_goods_need_min_inventory_count = values.count * goodsInUnion.son_goods_count
          //     if (item.take_count !== son_goods_need_min_inventory_count) {
          //       this.$message.warning('套盒数量与套盒内商品数量不一致')
          //       dataOK = false
          //     }
          //   } else {
          //     this.$message.warning('套盒数据不一致')
          //     dataOK = false
          //   }
          // })
          // if (!dataOK) {
          //   return
          // }
          values.price = Math.round(values.price * 100)
          values.actual_count = values.count
          values.status = 3
          const requestData = {
            goods_purchase: {
              ...values
            },
            goods_in_union: this.selectedGoodsListInUnion
          }
          custom_goods_purchase_create(requestData).then(res => {
            console.log(res)
            if (res.code === 1000) {
              this.handleGoBack()
            }
          })
        }
      })
    },
    addCustomGoodsInUnion () {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          console.log(values)
          this.custom_purchase_create_visible = true
        }
      })
    },
    handleCreateCustomPurchaseCancel () {
      this.custom_purchase_create_visible = false
      const form = this.$refs.createCustomPurchaseModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleCreateCustomPurchaseOk (selectedGoods) {
      this.selectedGoodsListInUnion = this.selectedGoodsListInUnion.map((item, index) => {
        if (item.son_goods_barcode === selectedGoods.goods_purchase_barcode) {
          if (Number(selectedGoods.available_count) < item.take_count) {
            this.$message.warning('库存不足')
          } else {
            console.log(selectedGoods)
            item.cargo_space = selectedGoods.cargo_space
            item.cargo_space_id = selectedGoods.cargo_space_id
            item.goods_purchase_batch = selectedGoods.goods_purchase_batch
            item.goods_purchase_id = selectedGoods.goods_purchase_id
            item.warehouse_id = selectedGoods.warehouse_id
          }
        }
        return item
      })
      this.handleCreateCustomPurchaseCancel()
      console.log(selectedGoods)
      // if (selectGoodsIsInUnionGoodsList === false) {
      //   this.$message.warning('商品不在套盒内')
      //   return
      // }
      //
      // this.form.validateFields((errors, values) => {
      //   if (!errors) {
      //     // 新增
      //     const son_goods_need_min_inventory_count = values.count * goodsInUnion.son_goods_count
      //     const goods_current_inventory_count = selectedGoods.available_count
      //     if (son_goods_need_min_inventory_count > goods_current_inventory_count) {
      //       this.$message.warning('库存不足')
      //     } else {
      //       this.selectedGoodsListInUnion.push({
      //         ...goodsInUnion,
      //         ...selectedGoods,
      //         take_count: son_goods_need_min_inventory_count
      //       })
      //       console.log(this.selectedGoodsListInUnion)
      //       this.custom_purchase_create_visible = false
      //     }
      //   } else {
      //     this.purchaseDeclarationConfirmLoading = false
      //   }
      // })
    },
    handleGoodsInUnion () {
      union_goods_list({ parent_goods_id: this.selectGoods.id }).then(res => {
        if (res.code === 1000) {
          this.goodsInUnionList = res.data.entries
          this.selectedGoodsListInUnion = this.goodsInUnionList.map(item => {
            item.take_count = multiply(item.son_goods_count, this.goodsUnitCount)
            return item
          })
        }
      })
    },
    handleDelete (record) {
      this.selectedGoodsListInUnion.forEach((item, index) => {
          console.log(index, record.son_goods_barcode, item)
          if (record.son_goods_barcode === item.son_goods_barcode) {
          this.selectedGoodsListInUnion.splice(index, 1)
        }
      })
    },
    chooseInventory (record) {
      const requestData = { barcode: record.son_goods_barcode, warehouse_id: this.goodsPurchaseMdl.warehouse_id }
      console.log('商品搜索', requestData)
      wms_warehouse_inventory_list(requestData).then(({ data }) => {
        const result = data.entries || []
        if (result.length === 0) {
          this.$message.warning('该货品暂无库存')
          return
        }
        this.form.validateFields((errors, values) => {
          if (!errors) {
            // 新增
            this.goodsPurchaseMdl.barcode = record.son_goods_barcode
            this.custom_purchase_create_visible = true
            console.log(this.goodsPurchaseMdl)
          }
        })
      }).finally(() => {
        this.goods_fetching = false
      })
    }
  }
}
</script>
